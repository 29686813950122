<template>
  <div>
    <div class="a">
      <img class="one" src="../../assets/img/WAP品牌介绍_02.jpg" alt="" />
    </div>
    <div class="b">
      <img class="one" src="../../assets/img/WAP品牌介绍_03.jpg" alt="" />
    </div>
    <div class="c">
      <img class="one" src="../../assets/img/WAP品牌介绍_04.jpg" alt="" />
    </div>
    <div class="d">
      <img class="one" src="../../assets/img/WAP品牌介绍_05.jpg" alt="" />
    </div>
    <div class="e">
      <img class="one" src="../../assets/img/WAP品牌介绍_06.jpg" alt="" />
    </div>
     <div class="f">
      <img class="one" src="../../assets/img/WAP品牌介绍_07.jpg" alt="" />
    </div>
    <div class="g">
      <img class="one" src="../../assets/img/WAP品牌介绍_08.jpg" alt="" />
    </div>
     <div class="h">
      <img class="one" src="../../assets/img/WAP品牌介绍_09.jpg" alt="" />
    </div>
     <div class="i">
      <img class="one" src="../../assets/img/WAP品牌介绍_10.jpg" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.i{
    height: 458px;
}
.h{
    height: 314px;
}
.g{
    height: 201px;
}
.f{
    height: 578px;
}
.a {
  height: 399px;
}
.b{
    height: 236px;
}
.c{
    height: 150px;
}
.d{
    height: 363px;
}
.e{
    height: 386px;
}
</style>